<template>
  <main class="subscription">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <section class="section">
        <div class="section-title subscription-title">Subscriptions</div>
        <div class="section-table subscription-table">
          <div class="section-table-status" v-if="isAdmin">
            <div class="row">
              <div class="col-auto">
                <router-link
                  class="btn btn-primary"
                  to="/platform/subscription/new"
                  >New Subscription</router-link
                >
              </div>
            </div>
          </div>
          <div class="section-table-content" v-if="subscriptionsShow">
            <CDataTable
              class="subscription-dataTable"
              :items="subscriptionData"
              :fields="fields"
              :table-filter="{
                label: 'Search:',
                placeholder: ''
              }"
              :pagination="{ align: 'center' }"
              :itemsPerPageSelect="{ values: [10, 20, 50, 100] }"
              hover
              :sorter="{ resetable: true }"
            >
              <template #sorting-icon="{state}">
                <TableSortingIcon :state="state"></TableSortingIcon>
              </template>
              <template #companyName="{item}">
                <td>
                  <router-link
                    v-if="item.companyName"
                    class="btn-link"
                    :to="`/platform/subscription/${item.id}/view`"
                    >{{ item.companyName }}
                  </router-link>
                </td>
              </template>
              <template #show_details="{item}">
                <td>
                  <div class="dataTable-btns" v-if="isAdmin">
                    <router-link
                      class="btn btn-outline-primary btn-sm dataTable-btn"
                      :to="`/platform/subscription/${item.id}/edit`"
                    >
                      Edit
                    </router-link>
                    <button
                      class="btn btn-outline-danger btn-sm dataTable-btn"
                      @click.prevent="deleteModalOpen(item)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </section>
    </div>
    <ModalDelete
      :isOpen="deleteModalShow"
      :deleteData="deleteData"
      @yes="subscriptionDelete"
      @no="deleteModalCancel"
    />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CDataTable } from '@coreui/vue/src'
import TableSortingIcon from '../components/TableSortingIcon.vue'
import ModalDelete from '../components/ModalDelete.vue'

export default {
  name: 'Subscription',
  components: { CDataTable, TableSortingIcon, ModalDelete },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Subscription',
          active: true
        }
      ],
      subscriptionsShow: false,
      fields: [
        { key: 'companyName', label: 'Company', _style: 'min-width:200px;' },
        {
          key: 'validPeriod',
          label: 'Valid Period',
          _style: 'min-width:220px;'
        },
        {
          key: 'maxNumOfActiveTasks',
          label: 'Max Number of Active Tasks',
          _style: 'min-width:220px;'
        },
        {
          key: 'show_details',
          label: '',
          sorter: false,
          filter: false
        }
      ],
      deleteModalShow: false,
      deleteData: {}
    }
  },
  async mounted() {
    let status = await this['subscription/pageHomeCreated']()
    if (status === 'success') {
      this.subscriptionsShow = true
    } else {
      console.log('subscription created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      userSelf: state => state.user.self,
      subscriptions: state => state.subscription.list
    }),
    subscriptionData() {
      if (!this.subscriptions) return []
      return this.subscriptions.map(subscription => {
        let validPeriod = this.validPeriod(subscription)
        return {
          id: subscription.id,
          companyName: subscription.companyName,
          validPeriod,
          maxNumOfActiveTasks: subscription.maxNumOfActiveTasks
        }
      })
    },
    isAdmin() {
      return this.userSelf.role === 'SYSTEM_ADMIN'
    }
  },
  methods: {
    ...mapActions([
      'subscription/pageHomeCreated',
      'subscription/pageHomeDelete'
    ]),
    validPeriod(subscription) {
      let validFrom = this.dateFormat(subscription.validFrom)
      let validUntil = this.dateFormat(subscription.validUntil)
      if (validFrom == null && validUntil == null) {
        return '-'
      } else if (validFrom == null) {
        return `to : ${validUntil}`
      } else if (validUntil == null) {
        return `from : ${validFrom}`
      } else {
        return `from : ${validFrom} to : ${validUntil}`
      }
    },
    dateFormat(date) {
      if (date == null) return null
      return new Date(date).toLocaleDateString()
    },
    deleteModalOpen(subscription) {
      this.deleteModalShow = true
      this.deleteData = {
        ...subscription,
        deleteType: 'subscription'
      }
    },
    deleteModalCancel() {
      this.deleteModalShow = false
      this.deleteData = {}
    },
    async subscriptionDelete() {
      this.deleteModalShow = false
      const id = this.deleteData.id
      let status = await this['subscription/pageHomeDelete']({ id })
      if (status === 'success') {
        this.deleteData = {}
      } else {
        this.deleteData = {}
        console.log('subscription delete: ' + status)
        // this.$router.push('/pages/page403')
      }
    }
  }
}
</script>
