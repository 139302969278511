var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"subscription"},[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('div',{staticClass:"container",class:{ 'mx-auto': !_vm.sidebarShow }},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"section-title subscription-title"},[_vm._v("Subscriptions")]),_c('div',{staticClass:"section-table subscription-table"},[(_vm.isAdmin)?_c('div',{staticClass:"section-table-status"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/platform/subscription/new"}},[_vm._v("New Subscription")])],1)])]):_vm._e(),(_vm.subscriptionsShow)?_c('div',{staticClass:"section-table-content"},[_c('CDataTable',{staticClass:"subscription-dataTable",attrs:{"items":_vm.subscriptionData,"fields":_vm.fields,"table-filter":{
              label: 'Search:',
              placeholder: ''
            },"pagination":{ align: 'center' },"itemsPerPageSelect":{ values: [10, 20, 50, 100] },"hover":"","sorter":{ resetable: true }},scopedSlots:_vm._u([{key:"sorting-icon",fn:function(ref){
            var state = ref.state;
return [_c('TableSortingIcon',{attrs:{"state":state}})]}},{key:"companyName",fn:function(ref){
            var item = ref.item;
return [_c('td',[(item.companyName)?_c('router-link',{staticClass:"btn-link",attrs:{"to":("/platform/subscription/" + (item.id) + "/view")}},[_vm._v(_vm._s(item.companyName)+" ")]):_vm._e()],1)]}},{key:"show_details",fn:function(ref){
            var item = ref.item;
return [_c('td',[(_vm.isAdmin)?_c('div',{staticClass:"dataTable-btns"},[_c('router-link',{staticClass:"btn btn-outline-primary btn-sm dataTable-btn",attrs:{"to":("/platform/subscription/" + (item.id) + "/edit")}},[_vm._v(" Edit ")]),_c('button',{staticClass:"btn btn-outline-danger btn-sm dataTable-btn",on:{"click":function($event){$event.preventDefault();return _vm.deleteModalOpen(item)}}},[_vm._v(" Delete ")])],1):_vm._e()])]}}],null,false,238799481)})],1):_vm._e()])])]),_c('ModalDelete',{attrs:{"isOpen":_vm.deleteModalShow,"deleteData":_vm.deleteData},on:{"yes":_vm.subscriptionDelete,"no":_vm.deleteModalCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }